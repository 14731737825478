<template>
  <div class="container">
    <b-card v-if="!kiosk" title="User Login" class="my-1 mx-auto" style="width: 20rem;">

      <div v-if="message" class="text-danger"> {{ message }} </div>
      <b-form v-on:submit="login">
        <b-form-input id="username" v-model="username" required placeholder="Username" class="my-1">
        </b-form-input>
        <b-form-input id="password" v-model="password" type="password" required placeholder="Password" class="my-1">
        </b-form-input>
        <b-button type="submit" variant="success">Login</b-button>
      </b-form>
    </b-card>

    <b-card v-if="kiosk" title="Kiosk Authorization" class="my-1 mx-auto" style="width: 20rem;">

      <div v-if="message" class="text-danger"> {{ message }} </div>
      <b-form v-on:submit="kiosk_login">
        <b-form-input id="username" v-model="username" required placeholder="Identity" class="my-1">
        </b-form-input>
        <b-form-input id="password" v-model="password" type="password" required placeholder="Token" class="my-1">
        </b-form-input>
        <b-button type="submit" variant="success">Authorize</b-button>
      </b-form>
    </b-card>
  </div>
</template>

<script>
  export default {
    data(){
      return {
        username : "",
        password : "",
        message: "",
        kiosk: this.$route.query.kiosk || false
      }
    },

    created: function (){
      if (this.kiosk){
        this.username = this.$store.getters.kioskIdent;
        this.password = this.$store.getters.kioskToken;

        if (this.username !== null && this.password !== null){
          this.kiosk_login(null)
        }
      }

      
    },

    methods: {
      login: function (evt) {
        evt.preventDefault();
        const username = this.username;
        const password = this.password;
        this.$store.dispatch('login', {username, password}).then((data) => {
          this.message = data;
          if (this.$store.getters.accessValid ){
            this.$router.push(this.$route.query.nextUrl || '/')
          }
        });
      },

      kiosk_login: function (evt) {
        if (evt !== null){
          evt.preventDefault();
        }
  
        this.$store.dispatch('kiosk_login', {ident: this.username, token: this.password}).then((data) => {
          this.message = data;
          if (this.$store.getters.accessValid ){
            this.$router.push(this.$route.query.nextUrl || '/')
          }
        });
      }
    }
  }
</script>